@font-face {
  font-family: Mattilda;
  src: url(./fonts/Mattilda.otf) format(opentype);
}

@font-face {
  font-family: Deutsch;
  src: url(./fonts/Deutsch.ttf) format(truetype);
}

@font-face {
  font-family: 'Minion Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Minion Pro Regular'), url('./fonts/MinionPro-Regular.woff') format('woff');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}